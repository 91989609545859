<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/"><img src="assets/img/logo_white.png" width="200px"/></a></h3>
        <ul>
            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="#" target="_blank" class="fab fa-skype"></a></li>
        </ul>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>
<div id="callme" (click)="goToContact()">
    <i class='fa fa-headset'></i>
<!--    <div>Etre rappelé</div>-->
</div>
