<div id="pricing" class="pricing-area pt-100 pb-70 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Exemples de <span>Rémunération</span></h2>
            <p>Selon votre profil, votre rémunération nette peut varier entre avantages, frais et cotisations
                sociales.</p>
        </div>
        <div class="row justify-content-center">

            <div class="form-group mb-3" style="width:250px; text-align: center;">
                <span style="font-size: 13px">Chiffre d'affaires mensuel</span>
                <input required name="rate" type="number" [value]="ca" class="form-control"
                       id="rate" (change)="compute($event)" style="text-align: center;font-size: 25px;width:230px">
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">Optimiseur</h3>
                    <div class="price-value">
                        <span class="currency">&#8364;</span>
                        <span class="amount">{{ca}}</span>
                        <span class="month">/20 jours</span>
                    </div>
                    <div class="pricing-content">
                        <table class="table table-borderless">
                            <tr>
                                <td class="simu-label">Tarif journalier</td>
                                <td>{{optimizedResult.dailyRate | number}} &#8364;</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Frais de gestion</td>
                                <td>{{optimizedResult.commission | number}} &#8364;</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Salaire brut</td>
                                <td>{{optimizedResult.brut | number}} &#8364;</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Salaire net</td>
                                <td>{{optimizedResult.net |number}} &#8364;</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Auto entreprise</td>
                                <td>{{optimizedResult.charges | number}} &#8364;</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Ratio</td>
                                <td>{{optimizedResult.ratio | number}}%</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Total net</td>
                                <td>{{optimizedResult.totalNet | number}} &#8364;</td>
                            </tr>
                        </table>
                    </div>
                    <a class="btn btn-primary" (click)="openSubscription(securedResult)">Souscrire</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">Sage</h3>
                    <div class="price-value">
                        <span class="currency">&#8364;</span>
                        <span class="amount">{{ca}}</span>
                        <span class="month">/20 jours</span>
                    </div>
                    <div class="pricing-content">
                        <table class="table table-borderless">
                            <tr>
                                <td class="simu-label">Tarif journalier</td>
                                <td>{{confidentResult.dailyRate | number}} &#8364;</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Frais de gestion</td>
                                <td>{{confidentResult.commission | number}} &#8364;</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Salaire brut</td>
                                <td>{{confidentResult.brut | number}} &#8364;</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Salaire net</td>
                                <td>{{confidentResult.net | number}} &#8364;</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Frais professionnels</td>
                                <td>{{confidentResult.charges | number}} &#8364;</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Ratio</td>
                                <td>{{confidentResult.ratio | number}}%</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Total net</td>
                                <td>{{confidentResult.totalNet | number}} &#8364;</td>
                            </tr>
                        </table>

                    </div>
                    <a class="btn btn-primary" (click)="openSubscription(securedResult)">Choisir</a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="pricingTable">
                    <h3 class="title">Sécuritaire</h3>
                    <div class="price-value">
                        <span class="currency">&#8364;</span>
                        <span class="amount">{{ca}}</span>
                        <span class="month">/20 jours</span>
                    </div>
                    <div class="pricing-content">
                        <table class="table table-borderless">
                            <tr>
                                <td class="simu-label">Tarif journalier</td>
                                <td>{{securedResult.dailyRate | number}} &#8364;</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Frais de gestion</td>
                                <td>{{securedResult.commission | number}} &#8364;</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Salaire brut</td>
                                <td>{{securedResult.brut | number}} &#8364;</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Salaire net</td>
                                <td>{{securedResult.net | number}} &#8364;</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Frais professionnels</td>
                                <td>{{securedResult.charges | number}} &#8364;</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Ratio</td>
                                <td>{{securedResult.ratio | number}}%</td>
                            </tr>
                            <tr>
                                <td class="simu-label">Total net</td>
                                <td>{{securedResult.totalNet | number}} &#8364;</td>
                            </tr>
                        </table>
                    </div>
                    <a class="btn btn-primary" (click)="openSubscription(securedResult)">Choisir</a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
