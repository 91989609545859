<div class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4 style="color:white">Vous hésitez encore ?</h4>
                <h2>Laissez nous vous guider</h2>
                <p>Remplissez le formulaire de contact, et notre équipe se chargera de vous faire rejoindre une blockchain de +1000 indépendants.</p>
                <a routerLink="/subscription" class="btn btn-primary">Commencer</a>
                <a (click)="onClick('contact')" class="btn btn-primary view-work">Contact</a>
            </div>
        </div>
    </div>
</div>
