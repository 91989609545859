import {Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {FormControl, Validators} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

    // rate: number = 500;
    ca: number  = 10000;
    optimizedResult: SimulationResult = new SimulationResult();
    confidentResult: SimulationResult = new SimulationResult();
    securedResult: SimulationResult = new SimulationResult();

    constructor(private viewportScroller: ViewportScroller, private router: Router) {
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
        this.compute(undefined);
    }

    compute($event){
        if($event) {
            this.ca = $event.target.value;
        }
        this.computeOptimized();
        this.computeConfident();
        this.computeSecured();
    }

    computeOptimized() {
        this.optimizedResult = new SimulationResult();
        this.optimizedResult.dailyRate = this.ca / 20;
        this.optimizedResult.commission = this.ca * 0.03 >= 100 ? this.ca * 0.03 : 100;
        this.optimizedResult.brut = 2554;
        this.optimizedResult.net = this.optimizedResult.brut * 0.77;
        this.optimizedResult.charges = this.ca - this.optimizedResult.commission - 3327 ;
        this.optimizedResult.totalNet = (this.optimizedResult.charges * 0.87 + this.optimizedResult.net);
        this.optimizedResult.ratio = this.optimizedResult.totalNet / this.ca * 100;
    }

    computeConfident() {
        this.confidentResult = new SimulationResult();
        let caIncome = this.ca / 2;

        this.confidentResult.dailyRate = this.ca / 20;
        this.confidentResult.commission = this.ca * 0.03 >= 100 ? this.ca * 0.03 : 100;
        this.confidentResult.brut = caIncome * 0.73;
        this.confidentResult.net = this.confidentResult.brut * 0.77;
        this.confidentResult.charges = this.ca - this.confidentResult.commission - caIncome;
        this.confidentResult.totalNet = (this.confidentResult.charges * 0.87 + this.confidentResult.net);
        this.confidentResult.ratio = this.confidentResult.totalNet / this.ca * 100;
    }

    computeSecured() {
        this.securedResult = new SimulationResult();
        this.securedResult.dailyRate = this.ca / 20;
        this.securedResult.commission = this.ca * 0.03 >= 100 ? this.ca * 0.03 : 100;

        let caIncome = this.ca - this.securedResult.commission;

        this.securedResult.brut = caIncome * 0.73;
        this.securedResult.net = this.securedResult.brut * 0.77;
        this.securedResult.charges = 0;
        this.securedResult.totalNet = (this.securedResult.charges * 0.87 + this.securedResult.net);
        this.securedResult.ratio = this.securedResult.totalNet / this.ca * 100;
    }

    openSubscription(result:SimulationResult)
    {
        this.router.navigateByUrl("/subscription");
    }


}

export class SimulationResult {
    dailyRate: number;
    commission: number;
    brut: number;
    net: number;
    charges: number;
    ratio: number;
    totalNet: number;
}
