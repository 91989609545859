import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LandingService} from "../services/landing.service";
import {catchError, throwError} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
    selector: 'app-subscription',
    templateUrl: './subscription.component.html',
    styleUrl: './subscription.component.scss'
})
export class SubscriptionComponent implements OnInit {

    form: FormGroup;

    contactSuccess: boolean;
    contactFailure: boolean;
    emailAlreadyUsed = false;

    constructor(private landingService: LandingService) {
    }

    ngOnInit() {
        this.form = new FormGroup<any>({
            firstName: new FormControl('', {validators: [Validators.required, Validators.minLength(2)]}),
            lastName: new FormControl('', {validators: [Validators.required, Validators.minLength(2)]}),
            email: new FormControl('', {validators: [Validators.required, Validators.email]}),
            phone: new FormControl('', {validators: [Validators.required]}),
            password: new FormControl('', {validators: [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$!%*?&])[A-Za-z\\d$@$!%*?&].{8,}')]}),
            confirmation: new FormControl('', {validators: [Validators.required]}),
            cgu: new FormControl('', {validators: [Validators.required]}),
            ca: new FormControl('', {validators: [Validators.required, Validators.min(2000)]}),
            situation: new FormControl('', {validators: [Validators.required]})
        }, {
            validators: this.confirmationPasswordValidator
        });
    }

    register() {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
        } else {
            this.landingService.subscribe({
                lastName: this.form.controls.lastName.value,
                firstName: this.form.controls.firstName.value,
                phone: this.form.controls.phone.value,
                email: this.form.controls.email.value,
                password: this.form.controls.password.value,
                situation: this.form.controls.situation.value,
                ca: this.form.controls.ca.value
            }).subscribe({
                next: (v) => this.handleSuccess(v),
                error: (e) => this.handleError(e)
            })
        }
    }

    private handleError(error: HttpErrorResponse) {
        this.contactSuccess = false;
        this.contactFailure = true;
        if(error.error && error.error.startsWith("L'email")) {
            this.emailAlreadyUsed = true;
        } else {
            this.emailAlreadyUsed = false;
        }
        return throwError(() => error)
    }

    private handleSuccess(v: Object) {
        this.contactSuccess = true;
        this.contactFailure = false;
    }


    openCGU() {
        window.open('https://www.startinio.com/#/subscription/cgu');
    }

    confirmationPasswordValidator(form: FormGroup) {

        if (form.controls.password!.errors && !form.controls.password!.errors?.['passwordMismatch']) {
            form.controls.confirmation.setErrors(null);
            return null;
        }

        if (
            (!form.controls.confirmation.touched || !form.controls.confirmation.value || !form.controls.password.value)
            ||
            (form.controls.password.value !== form.controls.confirmation.value)) {
            const error = {passwordMismatch: true};
            form.controls.confirmation.setErrors(error);
            return error;
        }

        form.controls.confirmation.setErrors(null);
        return null;
    }
}
