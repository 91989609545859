<nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    [class.active]="classApplied"
    [ngClass]="{'sticky': isSticky}"
>
    <div class="container">
        <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" width="200px"/></a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto" style="vertical-align:bottom ">
                <li class="nav-item"><span class="nav-link" (click)="onClick('who-we-are')">Avantages</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Simulation</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Contact</span></li>
                <li class="nav-item"><a class="nav-link" href="https://www.startinio.com/client/">Se connecter</a></li>
                <li class="nav-item"><a routerLink="/subscription" class="btn btn-navbar">Commencer</a></li>
            </ul>

            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="toggleClass();onClick('who-we-are')">Avantages</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass();onClick('pricing')">Simulation</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass();onClick('contact')">Contact</span></li>
                <li class="nav-item"><a class="nav-link" href="https://www.startinio.com/client/">Se connecter</a></li>
                <li class="nav-item"><a (click)="toggleClass();" routerLink="/subscription" class="btn btn-navbar">Commencer</a></li>
            </ul>
        </div>
    </div>
</nav>
