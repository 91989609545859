<div id="who-we-are" class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nos <span>Avantages</span></h2>
            <p>Vous garantir le meilleur service tout en optimisant votre rémunération au dernier centime.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>Expertise</h3>
                    <p>Bénéficiez de l'accompagnement et de l'expertise de notre équipe pour gérer au mieux votre carrière.</p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-lightbulb"></i>
                    <h3>A la pointe</h3>
                    <p>Nous restons en veille des nouvelles lois financières, et vous font profiter des nouveautés concernant votre montage.</p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-tag"></i>
                    <h3>Frais les plus bas</h3>
                    <p>Le budget economisé par nos modèles IA nous permet de vous proposer le meilleur tarif du marché.</p>
                    <span>3</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-hand-scissors"></i>
                    <h3>Rémunération modulaire</h3>
                    <p>Modulez votre rémunération comme bon vous semble. Entre salaires et frais, cibler efficacement vos attentes.</p>
                    <span>4</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fab fa-linode"></i>
                    <h3>Plateforme gratuite</h3>
                    <p>Abondonnez tous vos outils, utilisez une plateforme intélligente dont le secret réside en une autre intelligence.</p>
                    <span>5</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-life-ring"></i>
                    <h3>Conseil personnalisé</h3>
                    <p>Votre conseiller dédié est toujours disponible pour répondre à vos besoins et attentes personnelles.</p>
                    <span>6</span>
                </div>
            </div>
        </div>
    </div>
</div>
