import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class LandingService {

    constructor(private http: HttpClient) {
    }

    sendContactRequest(contactRequest: ContactRequest){
        return this.http.post(environment.backendUrl + '/api/landing/contact', contactRequest);
    }

    subscribe(subscriptionRequest: SubscriptionRequest){
        return this.http.post(environment.backendUrl + '/api/landing/subscription', subscriptionRequest);
    }

    activate(activationCode: string) {
        return this.http.post(environment.backendUrl + '/api/landing/activation', activationCode);
    }
}

export interface ContactRequest{
    name: string;
    email: string;
    phone: number;
    ca: number;
    message: string;
    situation: string;
}



export interface SubscriptionRequest{
    lastName: string;
    firstName: string;
    email: string;
    phone: number;
    password: string;
    ca: number;
    situation: string;
}
