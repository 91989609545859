import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeFourComponent} from './components/home-four/home-four.component';
import {SubscriptionComponent} from "./components/subscription/subscription.component";
import {CguComponent} from "./cgu/cgu.component";
import {ActivationComponent} from "./activation/activation.component";

const routes: Routes = [
    {path: '', component: HomeFourComponent},
    {path: 'subscription', component: SubscriptionComponent},
    {path: 'subscription/activation', component: ActivationComponent},
    {path: 'subscription/cgu', component: CguComponent},

    {path: '**', component: HomeFourComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        anchorScrolling: 'enabled', useHash: true
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
