<div class="main-banner item-bg-three ripple-effect jquery-ripples">
    <div class="d-table">
        <div class="d-table-cell" style="vertical-align: middle;">
            <div class="container" style="margin-bottom: 200px;">
                <div class="main-banner-text">
                    <h3>Guidé par les nouvelles technologies</h3>
                    <h1>Un <span>portage</span> à partir de 3%</h1>
                    <p>Vos contrats sont sécurisés dans une blockchain, et votre paie optimisée par l'IA sans frais
                        cachés.</p>
                    <a routerLink="/subscription" class="btn btn-primary">Commencer</a>
                    <a (click)="onClick('pricing')" class="btn btn-primary view-work">Exemple de simulation</a>
                    <div style="margin-top: 50px">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <i class="fas fa-laptop"></i><span  class="accroche">Une plateforme gratuite</span>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <i class="fas fa-headset"></i><span class="accroche">Un conseiller dédié</span>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <i class="fas fa-robot"></i><span  class="accroche">IA pour optimiser votre rémuneration</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<app-who-we-are></app-who-we-are>
<app-pricing></app-pricing>
<app-about></app-about>
<app-welcome></app-welcome>
<app-cta></app-cta>
<app-how-we-work></app-how-we-work>
<app-feedback></app-feedback>
<app-contact></app-contact>

<!--<app-strategy></app-strategy>-->
<!--<app-skill></app-skill>-->
<!--<app-funfacts></app-funfacts>-->
<!--<app-team></app-team>-->
<!--<app-services-two></app-services-two>-->
<!--<app-why-we-different></app-why-we-different>-->
<!--<app-work></app-work>-->
<!--<app-faq></app-faq>-->
<!--<app-blog></app-blog>-->
<!--<app-partner></app-partner>-->
<!--<app-subscribe></app-subscribe>-->

