<div class="page-title-area item-bg-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1>Souscription</h1>
                    <ul>
                        <li><a routerLink="/">Accueil</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li class="active">Souscription</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-img">
                        <img src="assets/img/thumbs-up-small.jpg" alt="blog-details">
                    </div>
                    <div class="article-text" style="text-align: center">
<!--                        <ul class="category">-->
<!--                            <li><a>Vous faites le bon choix</a></li>-->
<!--                        </ul>-->
                        <h4 class="title">Merci de nous avoir choisi</h4>
                        <p>Nous metterons tout en oeuvre pour répondre à vos exigences, et dès votre souscription,
                            un conseiller vous sera affecté pour vous accompagner dans votre aventure.</p>
                    </div>
                    <div class="row" style="margin-right: 0;margin-left: 0">
                        <div class="contact-form">
                            <form [formGroup]="form">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group mb-3">
                                            <label>Prénom</label>
                                            <input required name="firstName" type="text" formControlName="firstName" class="form-control" id="firstName">
                                            <div class="alert alert-danger" *ngIf="form.controls.firstName.touched && !form.controls.firstName.valid">
                                                <div *ngIf="form.controls.firstName.errors.required">Votre prénom est obligatoire.</div>
                                                <div *ngIf="form.controls.firstName.errors.minlength">Votre prénom doit avoir au moins 2 caractères.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group mb-3">
                                            <label>Nom</label>
                                            <input required name="lastName" type="text" formControlName="lastName" class="form-control" id="lastName">
                                            <div class="alert alert-danger" *ngIf="form.controls.lastName.touched && !form.controls.lastName.valid">
                                                <div *ngIf="form.controls.lastName.errors.required">Votre nom est obligatoire.</div>
                                                <div *ngIf="form.controls.lastName.errors.minlength">Votre nom doit avoir au moins 2 caractères.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group mb-3">
                                            <label>Email</label>
                                            <input required name="email" type="text" formControlName="email" class="form-control"
                                                   id="email">
                                            <div class="alert alert-danger" *ngIf="form.controls.email.touched && !form.controls.email.valid">L'email renseigné n'est
                                                pas valide.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group mb-3">
                                            <label>Numéro de téléphone</label>
                                            <input required name="number" type="number" formControlName="phone" class="form-control"
                                                   id="number">
                                            <div class="alert alert-danger" *ngIf="form.controls.phone.touched && !form.controls.phone.valid">Le numéro de
                                                téléphone saisi est incorrect.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group mb-3">
                                            <label>Mot de passe</label>
                                            <input required name="password" type="password" formControlName="password" class="form-control"
                                                   id="password">
                                            <div class="alert alert-danger" *ngIf="form.controls.password.touched && !form.controls.password.valid">
                                                <div>Le mot de passe doit avoir au moins 8 charactères et contenir au moins :</div>
                                                <ul>
                                                    <li>Une lettre au majuscule</li>
                                                    <li>Une lettre au miniscule</li>
                                                    <li>Un chiffre</li>
                                                    <li>Un caracètre spécial dans la liste [$!%*?&]</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group mb-3">
                                            <label>Confirmation</label>
                                            <input required name="confirmation" type="password" formControlName="confirmation" class="form-control"
                                                   id="confirmation">
                                            <div class="alert alert-danger" *ngIf="form.controls.confirmation.touched && form.errors?.passwordMismatch">
                                                    La confirmation ne correspond pas au mot de passe saisi.
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group mb-3">
                                            <label>Situation</label>
                                            <select class="form-select" required formControlName="situation" type="text"
                                                    id="situation">
                                                <option value="0" selected>J'ai déja une mission</option>
                                                <option value="1">Je suis en recherche active d'une mission</option>
                                                <option value="2">Je suis en fin de contrat avec une autre entreprise</option>
                                                <option value="3">Autre</option>
                                            </select>
                                            <div class="alert alert-danger" *ngIf="form.controls.situation.touched && !form.controls.situation.valid">Merci de
                                                sélectionner une situation.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group mb-3">
                                            <label>Chiffre d'affaire mensuel</label>
                                            <input required name="rate" type="number" formControlName="ca" class="form-control"
                                                   id="rate">
                                            <div class="alert alert-danger" *ngIf="form.controls.ca.touched && !form.controls.ca.valid">Merci d'indiquer
                                                un chiffre d'affaires mensuel >= 2000 euros.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group mb-3">
                                            <input required type="checkbox" name="cgu" formControlName="cgu" id="cgu"/>
                                            <div class="alert alert-danger" *ngIf="form.controls.cgu.touched && !form.controls.cgu.valid">Vous devez accepter les conditions générales d'utilisation pour pouvoir créer un compte.
                                            </div>
                                            <span style="font-size: 12px"> J'ai pris connaissance des <a (click)="openCGU()" style="text-decoration: underline">conditions générales d'utilisation</a> et accepte d’être contacté par un conseiller Startinio pour l'étude de mon dossier.</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="btn btn-primary" (click)="register()" >Créez votre compte</button>
                                    </div>
                                    <div style="padding:10px">
                                        <div class="alert alert-danger" *ngIf="contactFailure && !emailAlreadyUsed">Votre compte n'a malheureusement
                                            pas
                                            pu être créé. Nous vous proposons de nous joindre au numéro de téléphone affiché
                                            ci
                                            après.
                                        </div>
                                        <div class="alert alert-danger" *ngIf="contactFailure && emailAlreadyUsed">L'email saisi est déja utilisé. Nous vous invitons à vous connecter directement à votre espace.
                                        </div>
                                        <div class="alert alert-info" *ngIf="contactSuccess">Votre compte a bien été créé.
                                            Vous allez recevoir un mail sur l'adresse email indiquée pour l'activer et profiter pleinement de notre service. A bientôt.
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>



    </div>
</div>
