<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-img">
                        <img src="assets/img/thumbs-up-small.jpg" alt="blog-details">
                    </div>
                    <div class="article-text" style="text-align: center">
                        <h4 class="title">Merci de nous avoir choisi</h4>
                        <div *ngIf="activationSuccess" style="padding:20px">
                            <p>Votre compte est désormais activé, vous pouvez vous connecter et démarrer votre aventure dès maintenant.</p>
                            <a href="https://www.startinio.com/client/" class="btn btn-navbar">Se connecter</a>
                        </div>

                        <div *ngIf="activationFailure" style="padding:20px">
                            Nous n'avons pas pu valider votre compte.
                            <p *ngIf="!failureMessage">Assurez vous que le compte n'a pas déjà été confirmé, et sinon, veuillez prendre contact avec notre support technique</p>
                            <p *ngIf="failureMessage">{{failureMessage}}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>



    </div>
</div>

