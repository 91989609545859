<section class="feedback-area ptb-100">
    <div class="container">
        <div class="feedback-slides">
            <owl-carousel-o [options]="feedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“Je regrette le temps passé avec d'autres sociétés de portage. Avec Startinio, j'ai beaucoup moins de sollicitations et la technologie gère et s'occupe de toute ma paperasse.”</p>
                        <div class="info">
                            <h3>Zakaria</h3>
                            <span>Paris</span>
                            <img src="assets/img/feedback.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“Je paye beaucoup moins de frais comparé à avant, et en plus, je peux moduler ma rémunération comme si j'étais auto-entrepreneur.”</p>
                        <div class="info">
                            <h3>Clément</h3>
                            <span>Paris</span>
                            <img src="assets/img/feedback.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“La technologie fait tout simplement la différence chez Startinio. J'ai la meilleure offre du marché sans que je m'occupe de quoi que ce soit.”</p>
                        <div class="info">
                            <h3>Noemie</h3>
                            <span>Montpellier</span>
                            <img src="assets/img/feedback.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>
