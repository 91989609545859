import {Component, OnInit} from '@angular/core';
import {LandingService} from "../components/services/landing.service";
import {ActivatedRoute} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";
import {catchError, throwError} from "rxjs";

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrl: './activation.component.scss'
})
export class ActivationComponent implements OnInit{
    activationSuccess: boolean;
    activationFailure: boolean;
    failureMessage: string;

    constructor(private landingService: LandingService, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(data => {
            if(data.code){
                this.activate(data.code);
            }
            else {
                this.activationSuccess = false;
                this.activationFailure = true;
            }
        })
    }

    activate(activationCode: string){
        this.landingService.activate(activationCode).subscribe({
            next: (v) => this.handleSuccess(v),
            error: (e) => this.handleError(e)
        })
    }

    private handleError(error: HttpErrorResponse) {
        this.activationSuccess = false;
        this.activationFailure = true;
        this.failureMessage = error.error;
        return throwError(() => error)
    }

    private handleSuccess(v: Object) {
        this.activationSuccess = true;
        this.activationFailure = false;
    }
}
