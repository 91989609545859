import {Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {LandingService} from "../../services/landing.service";
import {HttpErrorResponse} from "@angular/common/http";
import {catchError, throwError} from "rxjs";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    contactSuccess: boolean;
    contactFailure: boolean;

    constructor(private viewportScroller: ViewportScroller, private landingService: LandingService) {
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }

    submit(form) {
        this.landingService.sendContactRequest({
            name: form.name,
            phone: form.phone,
            email: form.email,
            message: form.message,
            situation: form.situation,
            ca: form.ca
        }).pipe(catchError(this.handleError)).subscribe({
            next: (v) => this.handleSuccess(v),
            error: (e) => this.handleError(e)
        })
    }

    private handleError(error: HttpErrorResponse) {
        this.contactSuccess = false;
        this.contactFailure = true;
        return throwError(() => error)
    }

    private handleSuccess(v: Object) {
        this.contactSuccess = true;
        this.contactFailure = false;
    }

    openCGU(){
        window.open('https://www.startinio.com/#/subscription/cgu');
    }
}
