<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>Pourquoi Startinio ?</h4>
            <h2>Nous vous garantissons la <span>meilleure</span> offre du marché</h2>
            <p>En plus de la gestion administrative, nos conseillers vous aident à choisir la meilleure offre adaptée à vos besoins, une plateforme de suivi au quotidien et un support dédié.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Adaptable</h3>
                    <p>Adaptez votre paie à vos besoins : frais, salaires, mutuelle, avantages, et optimisez votre rémunération comme un chef d'entreprise.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop"></i>
                    </div>
                    <h3>Plateforme gratuite</h3>
                    <p>Accéder à une platforme gérée par l'intelligence artificielle et qui s'occupe de toutes vos tâches administratives.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-life-ring"></i>
                    </div>
                    <h3>Conseiller dédié</h3>
                    <p>Nous vous affectons un conseiller personnel qui vous guidera tout au long de votre contrat et répondra à toutes vos sollicitations.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>
