<section class="how-work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Comment ça <span>marche</span></h2>
            <p>Créez votre compte, signez votre CDI et nous nous occupons du reste.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-info-circle"></i>
                    </div>
                    <h3>Créer votre compte</h3>
                    <p>Vous pouvez à tout moment cliquer sur Commencer pour vous lancer dans l'aventure.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-drafting-compass"></i>
                    </div>
                    <h3>Signature du contrat</h3>
                    <p>Après validation de votre compte, la blockchain s'occupera d'éditer votre contrat de travail pour une signature bilatérale.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="far fa-paper-plane"></i>
                    </div>
                    <h3>Le tour est joué</h3>
                    <p>Vous poouvez aller vous reposer, nous prendrons contact avec vous pour la suite du processus.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>
