<div id="contact" class="contact-area ptb-100 ">
    <div class="container">
        <div class="section-title">
            <h4>Besoin d'assistance pour commencer?</h4>
            <h2>Laissez nous vous <span>guider</span></h2>
            <p>Nous vous proposerons l'offre qui s'adapte au mieux à vos besoins.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <p style="margin-bottom: 20px; font-size: 12px">Merci de remplir tous les champs pour activer le bouton d'envoi</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Nom et prénom</label>
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text"
                                           #name="ngModel" class="form-control" id="name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Votre nom est obligatoire.</div>
                                        <div *ngIf="name.errors.minlength">Votre nom doit
                                            dépsser {{ name.errors.minlength.requiredLength }} caractères.
                                        </div>
                                        <div *ngIf="name.errors.maxlength">Votre nom ne doit pas dépasser 50
                                            caractères.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email</label>
                                    <input required ngModel name="email" type="text" #email="ngModel"
                                           class="form-control" id="email">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Votre email
                                        est nécessaire.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Situation</label>
                                    <select class="form-select" required ngModel name="situation" type="text"
                                            #situation="ngModel" id="situation">
                                        <option selected>J'ai déja une mission</option>
                                        <option value="1">Je suis en recherche active d'une mission</option>
                                        <option value="2">Je suis en fin de contrat avec une autre entreprise</option>
                                        <option value="3">Autre</option>
                                    </select>
                                    <div class="alert alert-danger" *ngIf="situation.touched && !situation.valid">Merci
                                        de sélectionner une situation.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Numéro de téléphone</label>
                                    <input required ngModel name="phone" type="text" #phone="ngModel" minlength="10"
                                           class="form-control" id="phone" type="number">
<!--                                    <div class="alert alert-danger" *ngIf="phone.touched && !phone.valid">Le numéro de-->
<!--                                        téléphone saisi est incorrect.-->
<!--                                    </div>-->
                                    <div *ngIf="phone.touched && !phone.valid && phone.errors.minlength" class="alert alert-danger">Le numéro de téléphone saisi n'est pas valide.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Chiffre d'affaire mensuel</label>
                                    <input required ngModel name="ca" type="text" #ca="ngModel" class="form-control"
                                           type="number"
                                           id="ca">
                                    <div class="alert alert-danger" *ngIf="ca.touched && !ca.valid">Merci d'indiquer
                                        votre chiffre d'affaires mensuel.
                                    </div>
                                </div>
                            </div>
<!--                            <div class="col-lg-12 col-md-12">-->
<!--                                <div class="form-group mb-3">-->
<!--                                    <label>Message</label>-->
<!--                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30"-->
<!--                                              rows="4" class="form-control"></textarea>-->
<!--                                    <div class="alert alert-danger" *ngIf="message.touched && !message.valid">Message is-->
<!--                                        required.-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input required type="checkbox" ngModel #cgu="ngModel" name="cgu"  id="cgu"/>
                                    <div class="alert alert-danger" *ngIf="cgu.touched && !cgu.valid">Vous devez accepter les conditions générales d'utilisation pour pouvoir créer un compte.
                                    </div>
                                    <span style="font-size: 12px"> J'ai pris connaissance des <a (click)="openCGU()" style="text-decoration: underline">conditions générales d'utilisation</a> et accepte d’être contacté par un conseiller Startinio pour l'étude de mon dossier.</span>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Envoyer
                                </button>
                            </div>
                            <br/><br/>
                            <div style="padding:10px">
                                <div class="alert alert-danger" *ngIf="contactFailure">Votre message n'a malheureusement
                                    pas
                                    pu être envoyé. Nous vous proposons de nous joindre au numéro de téléphone affiché
                                    ci
                                    après.
                                </div>
                                <div class="alert alert-info" *ngIf="contactSuccess">Votre message a bien été envoyé.
                                    Nous
                                    vous réponderons dans les plus brefs délais
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="fas fa-map-marker-alt"></i> <span>Adresse:</span>24 rue de la brèche aux
                                    loups, 75012 Paris
                                </li>
                                <li><i class="far fa-envelope"></i> <a
                                    href="mailto:hello&#64;xavro.com"><span>Email:</span>contact&#64;startinio.com</a>
                                </li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:1244122445515"><span>Fixe:</span>(+33)669-62
                                    20 26</a></li>
                                <li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Horaires:</span> de 10h à
                                    18h</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
