import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import {Router} from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private viewportScroller: ViewportScroller, private router: Router) {}

  public onClick(elementId: string): void {
      this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
  }

  goToContact(){
      this.router.navigate(['/'], {fragment: 'contact'});
  }

}
