<div class="container blog-details-area ptb-100"><h1 class="h1 center">Conditions générales d'utilisation</h1>
    <div class="grid-article-sticky">
        <div>
            <p>Les présentes conditions générales d’utilisation sont conclues entre l’Utilisateur (tel que défini
                ci-après) et Startinio, société par actions simplifiée, dont le siège social est situé au 12 avenue du
                Général de Gaulle, Rosny sous bois immatriculée au registre du commerce et des
                sociétés de Bobigny sous le numéro 910 671 908 s’agissant de l’utilisation de la Solution (telle que
                définie ci-après).</p>
            <br/>
            <br/><br/>
            <h2>1.&nbsp;Définitions&nbsp;</h2>

            <p>Les définitions suivantes sont applicables à l’intégralité des CGU :&nbsp;</p>
            <ul role="list">
                <li>« <strong><em>CGU</em></strong> » : désigne les présentes conditions générales d’utilisation
                    conclues entre un Utilisateur et Freelancer Launchpad dans le cadre de l’utilisation de la Solution.
                </li>
                <li><strong>« <em>Compte</em> » </strong>: désigne l’espace numérique individualisé d’un Utilisateur qui
                    permet d’accéder à la Solution.
                </li>
                <li>« <strong><em>Fonctionnalité</em></strong> » : désigne les services dont les Utilisateurs peuvent
                    bénéficier, selon leur statut, et qui sont accessibles depuis la Solution.
                </li>
                <li>« <strong><em>Information Confidentielle</em></strong> » : désigne toutes les informations, données
                    ou documents de quelque nature que ce soit, y compris d’ordre technique, financier, commercial et
                    comptable, communiquées sous quelque forme que ce soit par une Partie à l'autre, en rapport avec les
                    Parties et/ou les CGU et qu'elles sont susceptibles d’échanger lors de la conclusion et/ou de
                    l’exécution des CGU.
                </li>
                <li>« <strong><em>Partie</em></strong> » : désigne un Utilisateur ou Freelancer Launchpad.</li>
                <li>« <strong><em>Règles sur les Données Personnelles</em></strong> » : désigne (i) le règlement (UE)
                    2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes
                    physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces
                    données, (ii) toute loi nationale relative aux données à caractère personnel dans sa dernière
                    version en vigueur ainsi que (iii) les règles non contraignantes relatives aux données à caractère
                    personnel qui ont été adoptées par les autorités de protection des données à caractère personnel.
                </li>
                <li>« <strong><em>Services Tiers</em></strong> » : désigne les services de toute entreprise ou toute
                    entité, indépendante de Freelancer Launchpad, qui sont référencés dans la Solution.
                </li>
                <li><strong>« <em>Solution</em> » </strong>: désigne le service en ligne accessible à l’adresse <a
                    href="https://startinio.com">https://startinio.com</a>, ainsi que l’ensemble de ses sous-domaines,
                    son apparence, son design et ses marques, permettant d’accéder aux Fonctionnalités et aux Services
                    Tiers.
                </li>
                <li>« <strong><em>Utilisateur</em></strong> » : désigne toute personne ayant accès un à Compte.&nbsp;
                </li>
            </ul>
            <p>Sauf exigence contraire résultant du contexte, les définitions au singulier incluent le pluriel, et
                inversement.&nbsp;</p>

            <br/><br/>
            <h2>2.&nbsp;Objet&nbsp;</h2>
            <p>Freelancer Launchpad développe, édite et exploite une solution logicielle dénommée « Startinio »
                permettant à
                des travailleurs en portage salarial de gérer leur activité professionnelle et leurs flux
                financiers.</p>
            <p>Les CGU ont pour objet de régir exclusivement les conditions d’accès et d’utilisation de la Solution par
                les Utilisateurs.</p>

            <p>Freelancer Launchpad se réserve la possibilité de modifier, ajouter ou supprimer des Fonctionnalités sous
                une forme et selon les modalités et moyens techniques qu’elle estimera les plus appropriés.</p>

            <br/>
            <h2>3.&nbsp;Acceptation des CGU&nbsp;</h2>
            <p>L’acceptation des CGU par l’Utilisateur est matérialisée par une case à cocher lors de l’étape&nbsp; de
                création du Compte.&nbsp;</p>
            <p>Plus généralement, tout accès ou utilisation de la Solution emporte acceptation expresse et sans réserve
                des CGU par l’Utilisateur.</p>

            <br/>
            <h2>4.&nbsp;Modification des CGU</h2>
            <p>Freelancer Launchpad peut modifier unilatéralement les dispositions des CGU relatives aux aspects
                techniques de la Solution et des Fonctionnalités dès lors qu’il n’en résulte ni augmentation de prix, ni
                altération de la qualité et que les caractéristiques auxquelles l’Utilisateur a subordonné son
                engagement ont pu figurer dans les CGU.</p>

            <p>En cas de modification substantielle des CGU, Freelancer Launchpad informera l’Utilisateur par tout moyen
                utile dans un délai de 15 (quinze) jours calendaires au moins avant leur entrée en vigueur.</p>

            <p>En l'absence d'objection par l'Utilisateur dans ce délai, les modifications seront considérées comme
                étant lues et acceptées. En cas d'objection ou de refus formalisé par l’Utilisateur, les CGU seront
                immédiatement résiliées et l'Utilisateur devra cesser d'utiliser la Solution.</p>

            <br/>
            <h2>5.&nbsp;Création et fonctionnement du compte</h2>
            <p>Toute utilisation de la Solution nécessite la création d’un Compte initiée par l’Utilisateur.</p>
            <p>Chaque Utilisateur s’engage à fournir l’ensemble des informations obligatoires et garantit que les
                informations renseignées sont exactes et ne sont entachées d’aucun caractère équivoque. L’Utilisateur
                s’engage à mettre immédiatement à jour les informations figurant sur le Compte en cas de modification de
                celles-ci.</p>

            <p>Lors de la création du Compte, Freelancer Launchpad utilise le nom, le prénom et l’adresse électronique
                fournie par l’Utilisateur. L’Utilisateur aura la possibilité de définir son mot de passe pour accéder à
                son Compte.</p>

            <br/>
            <h2>6. Modalités liées au Mandat de Prélèvement SEPA</h2>
            <p>En optant pour l'utilisation de la solution Startinio, l'Utilisateur accepte de manière irrévocable
                l'activation d'un mandat de prélèvement SEPA permettant à Startinio (ou son sous-traitant) de prélever
                les
                montants dus sur le compte bancaire fourni par l'utilisateur.</p>

            <p>Ce mandat de prélèvement SEPA sera déclenché dans le cas où le solde financier du compte Startinio de
                l'utilisateur serait inférieur à 0 euros, afin de couvrir les coûts associés et de maintenir
                l'accessibilité des services proposés par Startinio.</p>

            <p>Le rétablissement d'un solde financier positif sur le compte Startinio de l'utilisateur mettra fin à
                l'activation du mandat de prélèvement SEPA, jusqu'à ce que le solde retombe en dessous du seuil
                mentionné.</p>
            <p>L'acceptation du mandat de prélèvement SEPA est une condition sine qua non pour l'utilisation de la
                solution Startinio et constitue une acceptation sans réserve des termes énoncés dans cette clause.</p>


            <br/>
            <h2>7.&nbsp;Accès, usage, et sécurité&nbsp;</h2>
            <p>L’accès et l’utilisation de la Solution sont exclusivement réservés aux titulaires d’un Compte.
                L’Utilisateur comprend et accepte qu’il est responsable de l’accès et de l’utilisation de la Solution et
                qu’il doit en faire un usage strictement personnel.&nbsp;</p>

            <p>Sauf défaillance technique imputable à Freelancer Launchpad ou en cas de force majeure, chaque
                Utilisateur est responsable de la confidentialité et de la sécurité de ses données de connexion ainsi
                que des données répertoriées dans la Solution.&nbsp;</p>

            <p>Il est vivement recommandé à l’Utilisateur de changer son mot de passe à intervalles réguliers et
                d’utiliser un mot de passe sécurisé, en évitant les combinaisons facilement identifiables. Freelancer
                Launchpad impose une date d’expiration du mot de passe des Utilisateurs au-delà de laquelle ils seront
                invités à procéder à une modification de leur mot de passe.&nbsp;</p>

            <p>S’il a des raisons de croire que ses données de connexion ont été perdues, volées, détournées ou
                compromises d’une quelconque manière, ou en cas d’utilisation non autorisée de la Solution,
                l’Utilisateur doit immédiatement le notifier à Freelancer Launchpad par courrier électronique à <a
                    href="mailto:support&#64;startinio.com">support&#64;startinio.com</a>. En l’absence d’une telle
                notification, toute utilisation de la Solution est réputée avoir été faite par l’Utilisateur.&nbsp;</p>
            <p>L’Utilisateur comprend et accepte que les informations saisies aux fins de création ou de mise à jour de
                son Compte valent preuve de son identité. En cas de doute ou de question, Freelancer Launchpad peut
                demander des informations et documents complémentaires.</p>

            <p>Dans une telle situation et/ou en présence d’une menace potentielle ou avérée pour la sécurité ou la
                confidentialité des données de la Solution, l’Utilisateur autorise Freelancer Launchpad à prendre toutes
                les mesures appropriées pour empêcher tout accès non-autorisé (par exemple, réinitialiser le mot de
                passe et l’identifiant ou suspendre l’accès à la Solution).</p>

            <p>En cas de perte ou d'oubli du mot de passe, l’Utilisateur peut faire une requête sur la page d'accès à
                son Compte en cliquant sur « <em>Réinitialiser</em> ». Il recevra alors un lien pour réinitialiser son
                mot de passe à l'adresse électronique fournie lors de l'inscription.</p>

            <br/>
            <h2>8.&nbsp;Engagements de Freelancer Launchpad</h2>
            <br/><h3>8.1&nbsp;Hébergement des Comptes</h3>
            <p>Freelancer Launchpad s’engage à assurer, dans le cadre d’une obligation de moyens, l’hébergement des
                Comptes et de tout contenu de l’Utilisateur sur ses propres serveurs ou ceux d’un prestataire
                d’hébergement professionnel.</p>
            <p>À cet égard, Freelancer Launchpad s’engage à fournir à l’Utilisateur des capacités de stockage et de
                traitement suffisantes dans le cadre de l’utilisation de la Solution.</p>

            <p>Freelancer Launchpad s’engage à mettre en œuvre l’ensemble des moyens techniques nécessaires pour assurer
                la sécurité et l’accès à la Solution, portant sur la protection et la surveillance des infrastructures,
                le contrôle de l’accès physique et/ou immatériel auxdites infrastructures, ainsi que sur la mise en
                œuvre des mesures de détection, de prévention et de récupération pour protéger les serveurs d’actes
                malveillants.</p>

            <p>Freelancer Launchpad s’engage également à prendre toutes précautions utiles, au regard de la nature des
                données et des risques présentés par les traitements automatisés de données mis en œuvre pour les
                besoins de la Solution, pour préserver la sécurité des données, et notamment empêcher qu’elles soient
                déformées, endommagées ou que des tiers non autorisés y aient accès.</p>

            <br/><h3>8.2&nbsp;Continuité et qualité d’accès à la Solution</h3>
            <p>Freelancer Launchpad s’engage à assurer la permanence, la continuité et la qualité de l’accès à la
                Solution. À ce titre, Freelancer Launchpad fera ses meilleurs efforts pour maintenir un accès à la
                Solution 24h/24h, 7j/7j et garantit sa disponibilité à 98% sauf cas de force majeure.&nbsp;</p>

            <p>L’Utilisateur comprend et accepte que des ralentissements, perturbations ou interruptions d’accès à la
                Solution (et par conséquent, des Fonctionnalités) peuvent résulter de l’inégalité des capacités des
                différents sous-réseaux, de l’afflux à certaines heures, des différents goulots d’étranglement et, plus
                généralement, des aléas techniques liés à internet ou aux réseaux de télécommunications sur lesquels
                Freelancer Launchpad n’a aucune maîtrise. En conséquence, Freelancer Launchpad ne peut garantir une
                disponibilité stable et permanente de la Solution et la responsabilité de Freelancer Launchpad sera
                limitée au fonctionnement de ses serveurs, dont les limites extérieures sont constituées par les points
                de raccordement.</p>

            <p>En outre, l’Utilisateur est seul responsable de l’effectivité de sa connexion à internet et des
                navigateurs ou système d’exploitation qu’il utilise.&nbsp;</p>

            <br/><h3><strong>8.3 Maintenance</strong></h3>
            <p>Freelancer Launchpad s’engage à assurer la maintenance de la Solution, ainsi qu’à faire ses meilleurs
                efforts, dans le cadre d’une obligation de moyens, pour résoudre les éventuels incidents qui pourraient
                l’affecter. L’accès à la Solution peut être momentanément interrompu pour des raisons de nécessité liées
                au fonctionnement de la Solution et aux opérations de maintenance.</p>

            <p>L’Utilisateur comprend et accepte également que l’accès à la Solution (et par conséquent, aux
                Fonctionnalités) peut être momentanément interrompu en raison d’opérations de maintenance et de mise à
                jour.&nbsp;En cas de nécessité, Freelancer Launchpad se réserve la possibilité de limiter ou de
                suspendre l’accès à la Solution pour procéder à toute opération de maintenance et/ou d’amélioration.
                Dans cette hypothèse, Freelancer Launchpad s’engage à informer à l’avance l’Utilisateur de ces
                opérations de maintenance et/ou d’amélioration, dans un délai raisonnable, par tous moyens utiles et
                notamment par message informatif général sur la Solution de ces opérations de maintenance.</p>

            <p>Dans le cadre de ces opérations de maintenance et/ou d’évolution, Freelancer Launchpad s’engage à faire
                ses meilleurs efforts pour effectuer les sauvegardes des contenus stockés sur le Compte de l’Utilisateur
                et/ou la Solution.</p>

            <p>En cas de faille de sécurité constatée par Freelancer Launchpad de nature à compromettre gravement la
                sécurité de la Solution, Freelancer Launchpad pourra procéder, sans préavis, à une interruption
                momentanée de la Solution afin de remédier à la faille de sécurité dans les meilleurs délais. Dans cette
                hypothèse, l’Utilisateur ne pourra réclamer aucune indemnité ni mettre en jeu la responsabilité de
                Freelancer Launchpad pour l’interruption de la Solution.</p>

            <br/><h3>8.4 Support et assistance technique&nbsp;</h3>
            <p>Freelancer Launchpad propose à l’Utilisateur une assistance technique accessible par courrier
                électronique à l’adresse suivant : <a
                    href="mailto:support&#64;startinio.com">support&#64;startinio.com</a>.</p>

            <br/>
            <h2>9.&nbsp;Engagements des Utilisateurs</h2>
            <p>L’Utilisateur s’engage expressément à :&nbsp;</p>
            <ul role="list">
                <li>respecter les lois et règlements en vigueur dans le pays dans lequel il est établi et à ne pas
                    porter atteinte à l’ordre public, aux bonnes mœurs ou aux droits de tiers, et à n’enfreindre aucune
                    disposition législative ou réglementaire, notamment ses obligations déclaratives et de paiement
                    fiscales et sociales ;
                </li>
                <li>respecter les CGU ;&nbsp;</li>
                <li>utiliser la Solution conformément à sa destination et son objet, et en faire une utilisation loyale
                    vis-à-vis de Freelancer Launchpad et de ses filiales ;&nbsp;
                </li>
                <li>utiliser le Compte pour son propre usage et ne pas partager ses éléments de connexion avec d’autres
                    personnes physiques ou morales, qu’elles soient liées ou non à l’Utilisateur ;&nbsp;
                </li>
                <li>s’abstenir de créer des faux comptes ou utiliser la Solution sous une fausse identité ou en
                    fournissant des informations d’identification inexactes ;
                </li>
                <li>mettre en œuvre toutes les mesures de nature à assurer la sécurité et la confidentialité des données
                    de connexion ;
                </li>
                <li>informer Freelancer Launchpad en cas de complication de toute nature ;&nbsp;</li>
                <li>utiliser la Solution de manière loyale et s’abstenir, par quelque moyen que ce soit, de toute
                    tentative de fraude ou de surcharge des systèmes informatiques de Freelancer Launchpad.
                </li>
            </ul>
            <p>L’Utilisateur comprend et accepte qu’est strictement interdit : (i) tout comportement de nature à
                interrompre, suspendre, ralentir ou empêcher la continuité de la Solution (et par conséquent, des
                Fonctionnalités), (ii) l’intégration de contenus illicites (par exemple, des contenus pornographiques ou
                violant un droit de propriété intellectuelle), (iii) toute intrusion ou tentative d’intrusion dans les
                systèmes informatiques de Freelancer Launchpad, (iv) toute action de nature à imposer une charge
                disproportionnée sur les infrastructures numériques de Freelancer Launchpad, (v) toute atteinte aux
                mesures de sécurité et d’authentification, (vi) tout acte de nature à porter atteinte aux droits et
                intérêts financiers, commerciaux ou moraux de Freelancer Launchpad, (vii) toute copie et ou détournement
                de la Solution et/ou de l’infrastructure numérique de Freelancer Launchpad et (viii) plus généralement
                toute pratique détournant la Solution à des fins autres que celles pour lesquelles elle a été
                conçue.</p>
            <p>L’Utilisateur n’est pas autorisé à extraire, réutiliser, intégrer dans un autre site web, relier et/ou
                connecter de quelque manière que ce soit tout ou partie du contenu de la Solution. L'utilisation de
                programmes et de techniques de <em>data mining</em>, de robots, de <em>scraping</em> et/ou de collecte
                et d'extraction de données similaires est interdite.</p>

            <br/>
            <h2>10.&nbsp;Responsabilités</h2>
            <br/><h3><strong>10.1&nbsp;Responsabilité des Utilisateurs</strong></h3>
            <p>L’Utilisateur est seul responsable de son utilisation de la Solution, des Fonctionnalités et des Services
                Tiers, de sorte qu’il répond de tout préjudice qu’il est susceptible de causer du fait de son
                comportement et des informations inexactes, incomplètes et/ou trompeuses qu'il fournirait lors de son
                inscription ou en l'absence de mise à jour de ces informations, ce dont il assume seul les conséquences.&nbsp;</p>
            <p></p>
            <p>L’Utilisateur garantit Freelancer Launchpad contre toutes plaintes, réclamations, actions et/ou
                revendications quelconques qu’elle pourrait subir du fait de la violation, par l’Utilisateur, de l’une
                quelconque de ses obligations ou garanties aux termes des CGU.</p>

            <p>L’Utilisateur s’engage à indemniser Freelancer Launchpad de tout préjudice qu’elle subirait et à lui
                payer tous les frais, charges et/ou condamnations qu’elle pourrait avoir à supporter de ce fait.</p>

            <p>L’Utilisateur renonce à demander réparation à Freelancer Launchpad à quelque titre que ce soit, pour des
                dommages indirects. Par dommages indirects, on entend notamment, sans que cette liste ne soit
                exhaustive, les pertes de gains ou de profits, les dommages commerciaux, les conséquences de plaintes,
                d’actions ou de réclamations de tiers contre l’Utilisateur, même si l’Utilisateur a été averti de leur
                survenance.</p>

            <br/><h3><strong>10.2&nbsp;Responsabilité de Freelancer Launchpad</strong></h3><br/><h4>10.2.1&nbsp;Au titre de la
            Solution</h4>
            <p>Le rôle de Freelancer Launchpad est exclusivement limité à la mise à disposition de la Solution.</p>
            <p>Freelancer Launchpad ne pourra être tenue responsable des décisions et de l’utilisation de la Solution,
                des Fonctionnalités et des Services Tiers par l’Utilisateur, ou par tout tiers désigné par lui. De même,
                Freelancer Launchpad ne pourra en aucun cas être responsable du contenu publié par l’Utilisateur sur la
                Solution, sur lesquels elle n’exerce aucun contrôle, vérification ou modération d’aucune sorte.</p>

            <p>Les Fonctionnalités sont mises à disposition par Freelancer Launchpad telles quelles et sans garantie
                d’aucune sorte, expresse ou implicite. Freelancer Launchpad ne garantit notamment pas aux Utilisateurs
                (i) que les Fonctionnalités seront totalement exemptes d’erreurs, de vices ou défauts, et (ii) que les
                Fonctionnalités répondront spécifiquement à ses besoins et attentes.&nbsp;</p>

            <p>La création d’un Compte ne confère pas d’accès à un statut juridique particulier. En tout état de cause,
                la responsabilité de Freelancer Launchpad pourra être engagée dans les conditions de droit commun à
                raison des seuls dommages directs et prévisibles subis par l’Utilisateur, résultant d’un manquement à
                ses obligations contractuelles telles que définies dans les CGU.&nbsp;</p>
            <br/><h4>10.2.2&nbsp;Au titre des Services Tiers</h4>
            <p>L’Utilisateur est informé et reconnaît que les Services Tiers sont exécutés par des sociétés tierces,
                indépendantes de Freelancer Launchpad. À ce titre, Freelancer Launchpad se contente de les référencer et
                n’a aucun contrôle ou devoir de vérification quant à leur fiabilité ou efficacité.&nbsp;</p>

            <p>Tout litige concernant un Service Tiers devra être réglé directement avec la société en question et
                Freelancer Launchpad n’aura aucun rôle ou responsabilité à jouer dans ce cadre.&nbsp;</p>

            <br/>
            <h2><strong>11.&nbsp;Durée, Suspension et Résiliation</strong></h2>

            <br/><h3><strong>11.1&nbsp;Durée</strong></h3>
            <p>Les CGU sont conclues pour une durée indéterminée et entrent en vigueur à compter de leur acceptation par
                l’Utilisateur.&nbsp;</p>

            <br/><h3><strong>11.2&nbsp;Suspension du Compte</strong></h3>
            <p>Toute violation des CGU pourra immédiatement donner lieu à la suspension par Freelancer Launchpad de son
                Compte ou de l’accès à la Solution, sans notification ou information préalable. En particulier,
                Freelancer Launchpad pourra, selon la situation et sa propre appréciation :&nbsp;</p>
            <ul role="list">
                <li>limiter le recours ou l’accès à tout ou partie des Fonctionnalités ;&nbsp;</li>
                <li>suspendre le Compte ;&nbsp;</li>
                <li>supprimer tout contenu en lien avec le manquement ou l’infraction considéré(e), en totalité ou en
                    partie ;
                </li>
                <li>prendre toutes mesures appropriées et engager toute action en justice ;</li>
                <li>avertir le cas échéant les autorités compétentes, coopérer avec elles et leur fournir toutes les
                    informations utiles à la recherche et à la répression d’activités illégales ou illicites.
                </li>
            </ul>
            <p>Toute suspension, blocage ou limitation sera sans préjudice de la responsabilité de l’Utilisateur au
                titre des obligations des CGU.</p>

            <br/><h3><strong>11.3 Résiliation des CGU</strong></h3>
            <p>Moyennant un préavis de quinze (15) jours, les Parties peuvent résilier les CGU à tout moment, par tout
                moyen, et sans motif. La résiliation des CGU ne donnera lieu à aucune indemnité.</p>

            <p>Pendant cette période, l’Utilisateur continue d’avoir accès à la Solution (et par conséquent aux
                Fonctionnalités) et chaque Partie s'engage à respecter ses obligations. Au terme du préavis,
                l’Utilisateur n'aura plus accès au Compte, ni à la Solution (et par conséquent aux Fonctionnalités).</p>

            <p>En cas de résiliation des CGU, les contrats conclus au titre du Statut Freelance et du Statut Driver ne
                seront pas automatiquement affectés. L’Utilisateur comprend que l’accès à la Solution est nécessaire
                pour la gestion de son activité.&nbsp;</p>

            <br/>
            <h2><strong>12. Propriété intellectuelle</strong></h2>
            <p>Startinio est titulaire exclusif de l’ensemble des droits de propriété intellectuelle
                afférents à la Solution et aux Fonctionnalités ainsi que des éléments qui les composent, notamment le
                nom de domaine <a href="https://www.startinio.com">www.startinio.com</a>, le code source et le code
                objet, la marque française figurative n°4785483 (« Startinio »), les logiciels, structures,
                infrastructures,
                bases de données et contenus de toute nature (textes, images, visuels, logos, marques…).</p>
            <p>Toute utilisation, en ce compris toute forme de copie, d’adaptation, de distribution, d’aspiration, de
                reproduction, de désassemblage, de décompilation, de décryptage, d’extraction, ou de diffusion des
                éléments de la Solution (y compris le code source, le code objet et les bases de données), en tout ou
                partie, est formellement interdite sans accord préalable écrit de Startinio.</p>

            <p>Tous les droits qui ne sont pas expressément accordés par les CGU demeurent la propriété de Freelancer
                Launchpad, ne font pas partie des droits licenciés et relèvent de sa jouissance exclusive.&nbsp;</p>

            <br/>
            <h2><strong>13. Confidentialité</strong></h2>
            <p>Chaque Partie s’engage à ne pas divulguer à des tiers et à garder strictement confidentielles les
                informations, documents, éléments et données échangés dans le cadre des CGU et qui seraient expressément
                identifiés par l’autre Partie comme étant confidentiels. La Partie qui reçoit des Informations
                Confidentielles s’engage à ne pas les divulguer sans accord préalable de l’autre partie pendant toute la
                durée des CGU et pour une durée de cinq (5) ans à compter de leur terme.</p>

            <p>Les Informations Confidentielles le resteront entre les Parties sauf à y être contraintes (i) pour faire
                valoir leurs droits en justice, notamment - mais pas exclusivement - dans l'hypothèse d'une inexécution
                des présentes par l'une des Parties, (ii) par une décision de justice ou (iii) pour répondre à une
                demande d'une administration fiscale ou douanière, des organismes de recouvrement de cotisations
                sociales, d'un commissaire aux comptes, d’un expert-comptable ou d’un auditeur.</p>

            <p>Cette obligation ne s’étend pas non plus aux documents, éléments, données et informations (i) dont la
                Partie qui les reçoit avait déjà connaissance, (ii) étaient déjà publics lors de leur communication ou
                qui le deviendraient sans violation des CGU ou (iii) qui auraient été reçus d’un tiers de manière
                licite.</p>

            <p>Dans l’éventualité où l’une des Parties serait contrainte de divulguer ou révéler à un tiers les
                Informations Confidentielles, elle devra impérativement et préalablement en informer l'autre Partie dans
                les meilleurs délais par tout moyen (sauf s'il s'agit d'une demande d'une administration fiscale, d'un
                commissaire aux comptes, d’un expert-comptable ou d’un auditeur).</p>

            <br/>
            <h2><strong>14. Données personnelles&nbsp;</strong></h2>
            <p>Startinio accorde une grande importance au respect des Règles sur les Données
                Personnelles.</p>
            <p>Concernant les données à caractère personnel de l’Utilisateur, Startinio est responsable de
                traitement au sens des Règles sur les Données Personnelles. Pour en savoir plus sur le traitement et la
                sécurisation des données personnelles réalisés par Startinio, la politique de confidentialité
                est disponible à l’adresse suivante :&nbsp; https://www.startinio.com/politique-de-confidentialite.</p>

            <br/>
            <h2><strong>15.&nbsp;Divers</strong></h2>
            <br/><h3><strong>15.1&nbsp;Nullité</strong></h3>
            <p>Dans l’hypothèse où l’une des clauses des CGU serait déclarée nulle ou non avenue par un changement de
                législation, de réglementation ou par une décision de justice, cela ne saurait en aucun cas affecter la
                validité des autres clauses et le respect des CGU, à moins que la clause déclarée nulle ou non avenue
                n’ait un caractère essentiel, modifie l’interdépendance de certaines stipulations ou modifie l’économie
                générale des CGU.</p>

            <br/><h3><strong>15.2&nbsp;Cession des CGU</strong></h3>
            <p>L’Utilisateur ne peut céder ou transférer ses droits et obligations au titre des CGU à un tiers, sans
                l’accord préalable de Startinio.</p>

            <p>En cas (i) de fusion par constitution d’une nouvelle société, d’apport, d’apport partiel d’actif, de
                fusion-absorption, de scission, ou autre opération entraînant une transmission universelle du patrimoine
                de Startinio ou (ii) de toute opération entrainant un changement de contrôle, direct ou
                indirect, affectant Startinio, les relations contractuelles perdureront sans qu’il ne soit
                nécessaire d’informer ou d’obtenir le consentement de l’Utilisateur.</p>

            <br/><h3><strong>15.3&nbsp;Non renonciation</strong></h3>
            <p>Le fait pour une Partie de ne pas exiger l’application d’une stipulation des CGU, de façon permanente ou
                temporaire, n’est pas considéré comme une renonciation aux droits découlant de cette stipulation.</p>

            <br/><h3><strong>15.4&nbsp;Autorisation de diffusion des témoignages</strong></h3>
            <p>Pendant toute la durée des CGU, l’Utilisateur autorise Startinio à utiliser les témoignages
                qu’il publie dans la Solution aux fins de promotion de ceux-ci, selon les modalités suivantes :</p>
            <ul role="list">
                <li>l’Utilisateur consent à ce que son témoignage soit diffusé à titre gracieux par Startinio
                    dans la Solution et sur tous autres sites internet français ou étrangers, édités par toutes sociétés
                    avec lesquelles Startinio a des accords ;
                </li>
                <li>l’Utilisateur consent à ce que son témoignage soit diffusé par Startinio par tout moyen
                    et sur tout support aux fins de promotion de la Solution ou des Fonctionnalités ;
                </li>
                <li>l’Utilisateur accepte que son témoignage soit traduit en toutes langues pourvu que le sens de
                    celui-ci soit respecté ;
                </li>
                <li>l’Utilisateur reconnaît et accepte que son témoignage pourra faire l’objet de modifications,
                    notamment quant à son cadrage, son format et ses couleurs, ainsi que d’altérations ou de
                    dégradations dans sa qualité, en fonction des contraintes techniques de la Solution ou des
                    Fonctionnalités ;
                </li>
                <li>l’Utilisateur renonce à demander à Startinio une quelconque rémunération, redevance,
                    indemnité ou compensation financière à ce titre.
                </li>
            </ul>
            <br/><h3><strong>15.5&nbsp;Liens et sites tiers</strong></h3>
            <p>Startinio ne pourra en aucun cas être tenue responsable de la disponibilité technique de sites
                web exploités par des tiers (y compris les Services Tiers) auxquels l’Utilisateur accéderait par
                l’intermédiaire de la Solution.</p>

            <p>Startinio n’endosse aucune responsabilité au titre des contenus, publicités, produits et/ou
                services disponibles sur de tels sites tiers dont il est rappelé qu’ils sont régis par leurs propres
                conditions d’utilisation.</p>
            <p>Startinio n’est pas non plus responsable des transactions intervenues entre l’Utilisateur et
                un quelconque annonceur, professionnel ou commerçant (y compris les Services Tiers) vers lequel
                l’Utilisateur serait orienté par l’intermédiaire de la Solution et ne saurait en aucun cas être partie à
                quelques litiges éventuels que ce soit avec ces tiers concernant notamment l’achat de produits et/ou
                services, les garanties, déclarations et autres obligations quelconques auxquelles ces tiers sont
                tenus.</p>

            <br/><h3><strong>15.6&nbsp;Publicité&nbsp;</strong></h3>
            <p>Startinio se réserve la faculté d’insérer sur toute page de la Solution et dans toute
                communication à l’Utilisateur tous messages publicitaires ou promotionnels sous une forme et dans des
                conditions Startinio sera seule juge.</p>

            <br/>
            <h2><strong>16.&nbsp;résolution des litiges et droit applicable&nbsp;</strong></h2>
            <br/><h3><strong>16.1&nbsp;Droit applicable</strong></h3>
            <p>Les CGU sont régies et interprétées par le droit français.</p>

            <br/><h3><strong>16.2&nbsp;Résolution amiable</strong></h3>
            <p>En cas de litige, les Parties s’engagent à rechercher un mode de résolution amiable préalablement à toute
                saisine juridictionnelle. Pour toute réclamation relative à la Solution, Startinio peut être
                contactée directement contact&#64;startinio.com</p>

            <br/><h3><strong>16.3 Juridictions compétentes</strong></h3>
            <p>Tout litige ou contestation relatif à la validité, à l'interprétation, à l'exécution et/ou à la rupture
                des CGU sera soumis aux juridictions du ressort de la Cour d’appel de Paris.</p>
        </div>
        <div class="div-droit-copy"></div>
    </div>
</div>
