<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Une relation <span>transparente</span></h2>
            <p>Sans aucun frais cachés, votre paie est ajustée au plus petit centime.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Recevez mensuellement votre <span>bilan de rémunération</span></h2>
                        <p>Paramètrez votre robot de rémunération et comblez vos besoins financiers à tout moment :</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Paiements instantanés</li>
                        <li><i class="fas fa-check"></i>Paiements avant réglement client</li>
                        <li><i class="fas fa-check"></i>Souscription à une mutuelle</li>
                        <li><i class="fas fa-check"></i>Déclaration de frais professionnels</li>
                        <li><i class="fas fa-check"></i>Cumul chomage et retraite</li>
                        <li><i class="fas fa-check"></i>Lissage sur plusieurs mois</li>
                    </ul>
                    <p>Gardez le control sur votre facturation et gérez vos dépenses professionnelles au quotidien.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/ia_hand_woman.jpg" alt="about">
                    <div class="video-btn">
                        <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>
